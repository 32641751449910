import { ApolloClient, InMemoryCache, makeVar } from '@apollo/client';
import axios from 'axios';

export const loadingContentVar = makeVar(false);
export const currencyVar = makeVar([]);
export const newsVar = makeVar([]);
export const adsSettingVar = makeVar({});
export const lastUpdateVar = makeVar('');
export const showNotificationVar = makeVar(false);
export const showAdsVar = makeVar(false);
export const notificationDataVar = makeVar({
  title: '',
  message: '',
});
export const toastMessageVar = makeVar('');

async function loadCurrencies() {
  const response = await axios.get(
    `${process.env.CONTENT_URL}updates.json?time=${new Date().getTime()}`,
  );
  if (response.data && response.data.currencies) {
    currencyVar(response.data.currencies);
    lastUpdateVar(response.data.lastUpdate);
    const message = 'تم تحديث الأسعار!';
    //ToDO:: create toast web. ...
    console.log(message);
  }
}

async function loadNews() {
  const response = await axios.get(
    `${process.env.CONTENT_URL}news/info.json?time=${new Date().getTime()}`,
  );
  if (response.data) {
    newsVar(response.data);
  }
}

async function loadAdsSettings() {
  const response = await axios.get(
    `${process.env.CONTENT_URL}ads/info.json?time=${new Date().getTime()}`,
  );
  if (response.data) {
    adsSettingVar(response.data);
  }
}

export async function loadContent() {
  try {
    console.log('Loading...', `${process.env.CONTENT_URL}updates.json`);
    loadingContentVar(true);
    await loadCurrencies();
    await loadNews();
    loadingContentVar(false);
    console.log('Done...');
  } catch (e) {
    console.log('isssue retrive content news, currencies', e);
  }
}

if (typeof window !== 'undefined') {
  try {
    loadContent();
    loadAdsSettings().then();
  } catch (e) {
    console.log('isssue retrive ads', e);
  }
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        settings: {
          read() {
            return {
              normalAds: true,
              adDetail: {
                url: '',
                imageUrl: '',
                expireAt: null,
              },
            };
          },
        },
        currencies: {
          read() {
            return currencyVar();
          },
        },
        lastUpdate: {
          read() {
            return lastUpdateVar();
          },
        },
        news: {
          read() {
            return newsVar();
          },
        },
        adsSettings: {
          read() {
            return adsSettingVar();
          },
        },
      },
    },
  },
});

export const client = new ApolloClient({
  cache,
});
